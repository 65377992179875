<template>
  <b-overlay
    :show="ShowProduct"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="row">
        <div class="col-12">
          <!-- <img
            src="@/assets/images/anan-img/news/news.png"
            alt=""
            class="rounded img-fluid"
          /> -->
          <!-- <div class="card mt-1">
            <div class="card-header">
              <h3 class="mb-0">
                {{ $t("toDo") }}
                <small class="text-muted">{{ $t("tasksToHandle") }}</small>
              </h3>

            </div>
            <div class="card-body">
              <div
                class="to-do-box"
                style="transition: max-height 0.3s ease 0s"
              >
                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-order'
                    )
                  "
                  :to="{ name: 'admin-order', query: { querykey: 1 } }"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[0].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-222") }}
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-order'
                    )
                  "
                  :to="{ name: 'admin-order', query: { querykey: 2 } }"
                  href="/portal/shipment"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[1].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-223") }}
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-receipt'
                    )
                  "
                  :to="{ name: 'admin-finance-receipt' }"
                  href="/portal/shipment"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[2].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-224") }}
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-refill'
                    )
                  "
                  :to="{ name: 'admin-refill' }"
                  href="/portal/sale"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[3].inbox }}
                  </p>
                  <p class="item-desc">
                    รอตรวจสอบลูกค้าเติมเงิน
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-pay-importfee'
                    )
                  "
                  :to="{ name: 'admin-payment' }"
                  href="/portal/sale"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[7].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-226") }}
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-transfer'
                    )
                  "
                  :to="{ name: 'admin-transfer' }"
                  href="/portal/product/list/banned/action"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[4].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-227") }}
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-alipay'
                    )
                  "
                  :to="{ name: 'admin-china-alipay' }"
                  href="/portal/product/list/soldout"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[5].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-228") }}
                  </p>
                </router-link>

                <router-link
                  v-if="
                    UserData.ability.some(
                      (ele) => ele.subject === 'admin-payfor'
                    )
                  "
                  :to="{ name: 'admin-china-payfor' }"
                  href="/portal/web-my-campaigns/campaign"
                  class="to-do-box-aitem"
                >
                  <p class="item-title">
                    {{ items[6].inbox }}
                  </p>
                  <p class="item-desc">
                    {{ $t("key-229") }}
                  </p>
                </router-link>
              </div>
            </div>
          </div> -->

          <b-card
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            class="mt-2"
          >
            <template #header>
              <div class="d-flex items-left align-items-center">
                <feather-icon
                  icon="FlagIcon"
                  size="20"
                />
                <span class="mx-1 titles text-white">{{ $t("toDo") }}</span>
              </div>
              <!-- <span class="text-white titles">
                {{ $t("toDo") }}
              </span> -->
            </template>
            <div
              class="to-do-box"
              style="transition: max-height 0.3s ease 0s"
            >
              <router-link
                v-if="
                  UserData.ability.some((ele) => ele.subject === 'admin-order')
                "
                :to="{ name: 'admin-order', query: { querykey: 1 } }"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[0].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-222") }}
                  <!-- {{ $t('toPay') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some((ele) => ele.subject === 'admin-order')
                "
                :to="{ name: 'admin-order', query: { querykey: 2 } }"
                href="/portal/shipment"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[1].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-223") }}
                  <!-- {{ $t('toBeShipped') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some(
                    (ele) => ele.subject === 'admin-receipt'
                  )
                "
                :to="{ name: 'admin-finance-receipt' }"
                href="/portal/shipment"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[2].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-224") }}
                  <!-- {{ $t('shippingInProgress') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some((ele) => ele.subject === 'admin-refill')
                "
                :to="{ name: 'admin-refill' }"
                href="/portal/sale"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[3].inbox }}
                </p>
                <p class="item-desc">
                  รอตรวจสอบลูกค้าเติมเงิน
                  <!-- {{ $t('key-225') }} -->
                  <!-- {{ $t('pendingCancelRequest') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some(
                    (ele) => ele.subject === 'admin-pay-importfee'
                  )
                "
                :to="{ name: 'admin-payment' }"
                href="/portal/sale"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[7].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-226") }}
                  <!-- {{ $t('pendingReturnRefund') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some(
                    (ele) => ele.subject === 'admin-transfer'
                  )
                "
                :to="{ name: 'admin-transfer' }"
                href="/portal/product/list/banned/action"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[4].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-227") }}
                  <!-- {{ $t('suspendedPromotedProducts') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some((ele) => ele.subject === 'admin-alipay')
                "
                :to="{ name: 'admin-china-alipay' }"
                href="/portal/product/list/soldout"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[5].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-228") }}
                  <!-- {{ $t('outOfStock') }} -->
                </p>
              </router-link>

              <router-link
                v-if="
                  UserData.ability.some((ele) => ele.subject === 'admin-payfor')
                "
                :to="{ name: 'admin-china-payfor' }"
                href="/portal/web-my-campaigns/campaign"
                class="to-do-box-aitem"
              >
                <p class="item-title">
                  {{ items[6].inbox }}
                </p>
                <p class="item-desc">
                  {{ $t("key-229") }}
                  <!-- <span>{{ $t('pendingCampaigns') }}</span> -->
                  <!-- <span class="todo-new-icon">
                    <span class="text">New</span>
                  </span> -->
                </p>
              </router-link>
            </div>
          </b-card>

          <div class="row justify-content-center match-height">
            <div class="col-md-2">
              <b-card
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="card-dash"
              >
                <template #header>
                  <div class="d-flex items-left align-items-center">
                    <feather-icon
                      icon="ShoppingCartIcon"
                      size="20"
                    />
                    <span class="mx-1 titles text-white">{{
                      $t("key-313")
                    }}</span>
                  </div>
                </template>

                <h2 class="text-primary text-center mx-auto">
                  {{ Commas(orders) }}
                </h2>

                <template #footer>
                  <span
                    class="text-primary"
                    style="font-size: 11px"
                  >{{ $t("key-314") }}
                    <span
                      :class="per_order.Check ? 'text-primary' : 'text-danger'"
                    ><feather-icon
                      icon="ChevronUpIcon"
                      size="15"
                    />{{
                      Commas(per_order.total)
                    }}%</span>
                  </span>
                </template>
              </b-card>
            </div>

            <div class="col-md-2">
              <b-card
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="card-dash"
              >
                <template #header>
                  <div class="d-flex items-left align-items-center">
                    <feather-icon
                      icon="BoxIcon"
                      size="20"
                    />
                    <span class="mx-1 titles text-white">{{
                      $t("importProduct")
                    }}</span>
                  </div>
                </template>

                <h2 class="text-primary text-center mx-auto">
                  {{ Commas(Paymentin) }}
                </h2>

                <template #footer>
                  <span
                    class="text-primary"
                    style="font-size: 11px"
                  >{{ $t("key-314") }}
                    <span
                      :class="
                        per_Paymentin.Check ? 'text-success' : 'text-danger'
                      "
                    ><feather-icon
                      icon="ChevronUpIcon"
                      size="15"
                    />{{
                      Commas(per_Paymentin.total)
                    }}%</span>
                  </span>
                </template>
              </b-card>
            </div>

            <div class="col-md-2">
              <b-card
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="card-dash"
              >
                <template #header>
                  <div class="d-flex items-left align-items-center">
                    <feather-icon
                      icon="CreditCardIcon"
                      size="20"
                    />
                    <span class="mx-1 titles text-white">{{
                      $t("alipay-0")
                    }}</span>
                  </div>
                </template>

                <h2 class="text-primary text-center mx-auto">
                  {{ Commas(Alipays) }}
                </h2>

                <template #footer>
                  <span
                    class="text-primary"
                    style="font-size: 11px"
                  >{{ $t("key-314") }}
                    <span
                      :class="
                        per_Alipays.Check ? 'text-success' : 'text-danger'
                      "
                    /><span
                      :class="
                        per_Alipays.Check ? 'text-success' : 'text-danger'
                      "
                    ><feather-icon
                      icon="ChevronUpIcon"
                      size="15"
                    />{{
                      Commas(per_Alipays.total)
                    }}%</span>
                  </span>
                </template>
              </b-card>
            </div>

            <div class="col-md-2">
              <b-card
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="card-dash"
              >
                <template #header>
                  <div class="d-flex items-left align-items-center">
                    <feather-icon
                      icon="CornerUpRightIcon"
                      size="20"
                    />
                    <span class="mx-1 titles text-white">{{
                      $t("transferToChina")
                    }}</span>
                  </div>
                </template>

                <h2 class="text-primary text-center mx-auto">
                  {{ Commas(Transfer) }}
                </h2>

                <template #footer>
                  <span
                    class="text-primary"
                    style="font-size: 11px"
                  >{{ $t("key-314") }}
                    <span
                      :class="
                        per_Transfer.Check ? 'text-success' : 'text-danger'
                      "
                    ><feather-icon
                      icon="ChevronUpIcon"
                      size="15"
                    />{{
                      Commas(per_Transfer.total)
                    }}%</span>
                  </span>
                </template>
              </b-card>
            </div>

            <div class="col-md-2">
              <b-card
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="card-dash"
              >
                <template #header>
                  <div class="d-flex items-left align-items-center">
                    <feather-icon
                      icon="TagIcon"
                      size="20"
                    />
                    <span class="mx-1 titles text-white">{{
                      $t("key-315")
                    }}</span>
                  </div>
                </template>

                <h2 class="text-primary text-center mx-auto">
                  {{ Commas(Payment) }}
                </h2>

                <template #footer>
                  <span
                    class="text-primary"
                    style="font-size: 11px"
                  >{{ $t("key-314") }}
                    <span
                      :class="
                        per_Payments.Check ? 'text-success' : 'text-danger'
                      "
                    ><feather-icon
                      icon="ChevronDownIcon"
                      size="15"
                    />{{
                      Commas(per_Payments.total)
                    }}%</span>
                  </span>
                </template>
              </b-card>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div>
                  <span class="titles">{{ $t("business-insights") }}</span>
                  <!-- <span class="title-tip">( อัปเดทล่าสุด GMT+7 17:00 )</span> -->
                  <!-- <p class="card-tips">
                  {{ $t('orderOverviewConfirmed') }}
                </p> -->
                </div>

                <!-- <router-link :to="{ name: 'notify' }">
                <small class="mb-0">
                  {{ $t('more') }} <i class="fas fa-angle-right" />
                </small>
              </router-link> -->

                <!-- <div class="col-12">
                <div class="row">

                  <div class="col-8"> -->

                <!-- <flat-pickr
                      v-model="form.monthSelect"
                      class="form-control"
                      :config="configs.monthSelect"
                    /> -->
                <div>
                  <!-- <flat-pickr
                    v-model="dateStart"
                    class="form-control w-100"
                    :placeholder="$t('selectStartTime')"
                    :config="{ altInput: true,altFormat: 'm-Y'}"
                  /> -->
                  <span class="font-weight-bold mr-1">{{ $t("key-230") }}</span>
                  <date-picker
                    v-model="date"
                    type="month"
                    format="เดือน MMM ปีYYYY"
                    :placeholder="$t('key-231')"
                    @change="handleChange"
                  />
                </div>
                <!-- @change="getGraphData(date), Getproductall(date),Dashboard(date)" -->
                <!-- <div class="col-4">
                  <button
                    class="btn btn-gradient-dark inline"
                  >
                    <feather-icon icon="SearchIcon" />
                    {{ $t('search') }}
                  </button>
                </div> -->
              </div>
              <!-- <div class="col-4">
                    <div class="form-group">
                      <label>ㅤ</label>
                      <div><button
                        class="btn btn-gradient-dark"
                      >
                        <feather-icon icon="SearchIcon" />
                        {{ $t('search') }}
                      </button></div>
                    </div>
                  </div> -->

              <hr>
              <b-overlay
                :show="ShowProduct"
                variant="transparent"
                opacity="0.99"
                blur="5px"
                rounded="sm"
              >
                <template #overlay>
                  <div class="text-center">
                    <b-icon-controller
                      font-scale="3"
                      animation="cylon"
                    />
                    <p id="cancel-label">
                      กรุณารอสักครู่...
                    </p>
                  </div>
                </template>
                <!-- <b-row class="justify-content-center gap-5">
                  <b-col
                    xl="2"
                    md="4"
                    sm="6"
                    class="shadow-sm bg-warning rounded-lg"
                  >
                    <statistic-card-vertical
                      color="danger"
                      icon="ShoppingBagIcon"
                      :statistic="Commas(postunmer.reduce((total, acc) => total + (acc.charge_per_save === acc.charge_per_kilo ? acc.charge_per_kilo : 0), 0))"
                      :statistic-title="$t('kilo')"
                      class="shadow mt-2"
                    />
                  </b-col>
                  <b-col
                    xl="2"
                    md="4"
                    sm="6"
                    class="shadow-sm bg-primary rounded-lg"
                  >
                    <statistic-card-vertical
                      color="primary"
                      icon="ListIcon"
                      :statistic="Commas(postunmer.reduce((total, acc) => total + (acc.charge_per_save === acc.charge_per_cue ? acc.charge_per_cue : 0), 0))"
                      :statistic-title="$t('key-18')"
                      class="shadow mt-2"
                    />
                  </b-col>
                </b-row> -->

                <div class="row math-height">
                  <!-- <div class="col-md-12 p-2">
                    <div class="row gap justify-content-center"> -->
                  <!-- <div class="col-md-2 mx-auto text-center db-card">
                        <div class="text-left">
                          <feather-icon icon="BoxIcon" size="15" />
                          <span> {{ $t("importProduct") }}</span>
                          <h2>{{ Commas(Paymentin) }}</h2>
                          <span class="text-muted" style="font-size: 11px"
                            >{{ $t("key-314") }}
                            <span
                              :class="
                                per_Paymentin.Check
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                              ><feather-icon icon="ChevronUpIcon" size="15" />{{
                                Commas(per_Paymentin.total)
                              }}%</span
                            >
                          </span>
                        </div>
                      </div> -->
                  <!-- <div class="col-md-2 mx-auto text-center db-card">
                        <div class="text-left">
                          <feather-icon icon="CreditCardIcon" size="15" />
                          <span> {{ $t("alipay-0") }}</span>
                          <h2>{{ Commas(Alipays) }}</h2>
                          <span class="text-muted" style="font-size: 11px"
                            >{{ $t("key-314") }}
                            <span
                              :class="
                                per_Alipays.Check
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            /><span
                              :class="
                                per_Alipays.Check
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                              ><feather-icon icon="ChevronUpIcon" size="15" />{{
                                Commas(per_Alipays.total)
                              }}%</span
                            >
                          </span>
                        </div>
                      </div> -->
                  <!-- <div class="col-md-2 mx-auto text-center db-card">
                        <div class="text-left">
                          <feather-icon icon="CornerUpRightIcon" size="15" />
                          <span> {{ $t("transferToChina") }}</span>
                          <h2>{{ Commas(Transfer) }}</h2>
                          <span class="text-muted" style="font-size: 11px"
                            >{{ $t("key-314") }}
                            <span
                              :class="
                                per_Transfer.Check
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                              ><feather-icon icon="ChevronUpIcon" size="15" />{{
                                Commas(per_Transfer.total)
                              }}%</span
                            >
                          </span>
                        </div>
                      </div> -->
                  <!-- <div class="col-md-2 mx-auto text-center db-card">
                        <div class="text-left">
                          <feather-icon icon="TagIcon" size="15" />
                          <span> {{ $t("key-315") }} </span>
                          <h2>{{ Commas(Payment) }}</h2>
                          <span class="text-muted" style="font-size: 11px"
                            >{{ $t("key-314") }}
                            <span
                              :class="
                                per_Payments.Check
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                              ><feather-icon
                                icon="ChevronDownIcon"
                                size="15"
                              />{{ Commas(per_Payments.total) }}%</span
                            >
                          </span>
                        </div>
                      </div> -->
                  <!-- </div>
                  </div> -->
                  <div class="col-md-12">
                    <b-card
                      border-variant="primary"
                      header-bg-variant="primary"
                      header-text-variant="white"
                    >
                      <!-- <template #header>
                        <span class="text-white titles">
                          {{ $t("key-316") }}
                        </span>
                      </template> -->
                      <template #header>
                        <div class="d-flex items-left align-items-center">
                          <feather-icon
                            icon="TrendingUpIcon"
                            size="20"
                          />
                          <span class="mx-1 titles text-white">{{
                            $t("key-316")
                          }}</span>
                        </div>
                      </template>

                      <vue-apex-charts
                        type="line"
                        height="350"
                        :options="data4.chartOptions"
                        :series="data4.series"
                      />
                    </b-card>
                  </div>

                  <div class="col-md-6 mx-auto">
                    <b-card
                      border-variant="primary"
                      header-bg-variant="primary"
                      header-text-variant="white"
                    >
                      <!-- <template #header>
                        <span class="text-white titles">
                          {{ $t("paid") }}
                        </span>
                      </template> -->
                      <template #header>
                        <div class="d-flex items-left align-items-center">
                          <feather-icon
                            icon="CheckIcon"
                            size="20"
                          />
                          <span class="mx-1 titles text-white">{{
                            $t("paid")
                          }}</span>
                        </div>
                      </template>
                      <b-table
                        tbody-class="table-light"
                        responsive
                        :per-page="perPage"
                        :items="product_type"
                        :fields="fields"
                        show-empty
                        foot-clone
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner
                              label="กำลังโหลด..."
                              variant="danger"
                            />
                          </div>
                        </template>

                        <template #empty>
                          <div class="text-center">
                            <img
                              src="@/assets/images/anan-img/empty/empty.png"
                              alt="empty"
                              width="180px"
                            >
                            <p>No Data</p>
                          </div>
                        </template>
                        <template #cell(proKiloCar)="data">
                          {{ Commas(data.item.proKiloCar) }}
                        </template>
                        <template #cell(proqueueCar)="data">
                          {{ CommasCue(data.item.proqueueCar) }}
                        </template>
                        <template #cell(proKiloShip)="data">
                          {{ Commas(data.item.proKiloShip) }}
                        </template>
                        <template #cell(proqueueShip)="data">
                          {{ CommasCue(data.item.proqueueShip) }}
                        </template>

                        <template #foot(proKiloCar)="">
                          {{ Commas(totalProKiloCar) }}
                        </template>
                        <template #foot(proqueueCar)="">
                          {{ CommasCue(totalProqueueCar) }}
                        </template>
                        <template #foot(proKiloShip)="">
                          {{ Commas(totalProKiloShip) }}
                        </template>
                        <template #foot(proqueueShip)="">
                          {{ CommasCue(totalProqueueShip) }}
                        </template>
                        <template #foot(pro_name)="">
                          {{ $t("total") }}
                        </template>
                      </b-table>
                    </b-card>
                    <!-- <div class="demo-spacing-0 d-flex justify-content-end">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @input="getData(status, search_val)"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div> -->
                  </div>
                  <div class="col-md-6 mx-auto">
                    <b-card
                      border-variant="primary"
                      header-bg-variant="primary"
                      header-text-variant="white"
                    >
                      <!-- <template #header>
                        <span class="text-white titles">
                          {{ $t("enterWarehouse") }}
                        </span>
                      </template> -->
                      <template #header>
                        <div class="d-flex items-left align-items-center">
                          <feather-icon
                            icon="HomeIcon"
                            size="20"
                          />
                          <span class="mx-1 titles text-white">{{
                            $t("enterWarehouse")
                          }}</span>
                        </div>
                      </template>
                      <b-table
                        tbody-class="table-light"
                        responsive
                        :per-page="perPage"
                        :items="product_type1"
                        :fields="fields"
                        show-empty
                        foot-clone
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner
                              label="กำลังโหลด..."
                              variant="danger"
                            />
                          </div>
                        </template>

                        <template #empty>
                          <div class="text-center">
                            <img
                              src="@/assets/images/anan-img/empty/empty.png"
                              alt="empty"
                              width="180px"
                            >
                            <p>No Data</p>
                          </div>
                        </template>
                        <template #cell(proKiloCar)="data">
                          {{ Commas(data.item.proKiloCar) }}
                        </template>
                        <template #cell(proqueueCar)="data">
                          {{ CommasCue(data.item.proqueueCar) }}
                        </template>
                        <template #cell(proKiloShip)="data">
                          {{ Commas(data.item.proKiloShip) }}
                        </template>
                        <template #cell(proqueueShip)="data">
                          {{ CommasCue(data.item.proqueueShip) }}
                        </template>

                        <template #foot(proKiloCar)="">
                          {{ Commas(totalProKiloCar_2) }}
                        </template>
                        <template #foot(proqueueCar)="">
                          {{ CommasCue(totalProqueueCar_2) }}
                        </template>
                        <template #foot(proKiloShip)="">
                          {{ Commas(totalProKiloShip_2) }}
                        </template>
                        <template #foot(proqueueShip)="">
                          {{ CommasCue(totalProqueueShip_2) }}
                        </template>
                        <template #foot(pro_name)="">
                          {{ $t("total") }}
                        </template>
                      </b-table>
                    </b-card>
                    <!-- <div class="demo-spacing-0 d-flex justify-content-end">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @input="getData(status, search_val)"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div> -->
                  </div>

                  <div class="col-md-12">
                    <!-- <b-card
                      :header="$t('key-232')"
                      class="text-center table-container"
                    > -->
                    <b-card
                      border-variant="primary"
                      header-bg-variant="primary"
                      header-text-variant="white"
                    >
                      <!-- <template #header>
                        <span class="text-white titles">
                          {{ $t("key-232") }}
                        </span>
                      </template> -->
                      <template #header>
                        <div class="d-flex items-left align-items-center">
                          <feather-icon
                            icon="TagIcon"
                            size="20"
                          />
                          <span class="mx-1 titles text-white">{{
                            $t("key-232")
                          }}</span>
                        </div>
                      </template>
                      <vue-apex-charts
                        type="bar"
                        class="fix-head"
                        height="350"
                        :options="data1.chartOptions"
                        :series="data1.series"
                      />
                    </b-card>
                  </div>

                  <!-- <div class="col-md-12 shadow-sm">
                <b-card header="รายการน้ำหนัก/คิว นำเข้าแต่ละเดือน" class="text-center">
                  <vue-apex-charts type="bar" class="fix-head" height="350" :options="data5.chartOptions"
                    :series="data5.series" />
                </b-card>
              </div> -->

                  <div class="col-md-12">
                    <!-- <b-card :header="$t('key-233')" class="text-center"> -->
                    <b-card
                      border-variant="primary"
                      header-bg-variant="primary"
                      header-text-variant="white"
                    >
                      <!-- <template #header>
                        <span class="text-white titles">
                          {{ $t("key-233") }}
                        </span>
                      </template> -->
                      <template #header>
                        <div class="d-flex items-left align-items-center">
                          <feather-icon
                            icon="UsersIcon"
                            size="20"
                          />
                          <span class="mx-1 titles text-white">{{
                            $t("key-233")
                          }}</span>
                        </div>
                      </template>
                      <vue-apex-charts
                        type="bar"
                        height="350"
                        :options="data2.chartOptions"
                        :series="data2.series"
                      />
                    </b-card>
                  </div>

                  <div class="col-md-12">
                    <!-- <b-card :header="$t('key-234')" class="text-center"> -->
                    <b-card
                      border-variant="primary"
                      header-bg-variant="primary"
                      header-text-variant="white"
                    >
                      <!-- <template #header>
                        <span class="text-white titles">
                          {{ $t("key-234") }}
                        </span>
                      </template> -->

                      <template #header>
                        <div class="d-flex items-left align-items-center">
                          <feather-icon
                            icon="CornerLeftUpIcon"
                            size="20"
                          />
                          <span class="mx-1 titles text-white">{{
                            $t("key-234")
                          }}</span>
                        </div>
                      </template>
                      <vue-apex-charts
                        type="bar"
                        height="350"
                        :options="data3.chartOptions"
                        :series="data3.series"
                      />
                    </b-card>
                  </div>

                  <!-- <div class="col-md-12 mt-1 shadow-sm">
                    <b-card
                      :header="$t('key-235')"
                      class="text-center"
                    >
                      <vue-apex-charts
                        type="line"
                        height="350"
                        :options="data4.chartOptions"
                        :series="data4.series"
                      />
                    </b-card>
                  </div> -->
                  <!-- <div class="col-md-6">
                <div class="list-box">
                  <div class="itemer">
                    <div class="item-box text-overflow">
                      <p class="item-titles text-overflow">
                        {{ $t('visitorCount') }}
                      </p>
                      <div class="anan-popover anan-popover--light">
                        <div
                          class="anan-popper anan-popover__popper anan-popover__popper--light with-arrow"
                          style="display: none; max-width: 320px;"
                        >
                          <div class="anan-popover__content">
                            psd_tooltip_unique_views
                          </div>
                        </div>
                      </div>
                      <p class="item-number text-overflow">
                        0
                      </p>
                      <div class="item-increase text-overflow">
                        <p class="text-overflow">
                          {{ $t('comparedToYesterday') }}
                        </p>
                        <div class="item-increase-number">
                          <p
                            class="text-overflow"
                            style="line-height: 14px;"
                          >
                            0.00%
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="item-box text-overflow">
                      <p class="item-titles text-overflow">
                        {{ $t('visitCount') }}
                      </p>
                      <div class="anan-popover anan-popover--light">
                        <div
                          class="anan-popper anan-popover__popper anan-popover__popper--light with-arrow"
                          style="display: none; max-width: 320px;"
                        >
                          <div class="anan-popover__content">
                            {{ $t('key-85') }}
                          </div>
                        </div>
                      </div>
                      <p class="item-number text-overflow">
                        0
                      </p>
                      <div class="item-increase text-overflow">
                        <p class="text-overflow">
                          {{ $t('comparedToYesterday') }}
                        </p>
                        <div class="item-increase-number">
                          <p
                            class="text-overflow"
                            style="line-height: 14px;"
                          >
                            0.00%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="itemer">
                    <div class="item-box text-overflow">
                      <p class="item-titles text-overflow">
                        {{ $t('orders') }}
                      </p>
                      <div class="anan-popover anan-popover--light">
                        <div class="anan-popover__ref" />
                        <div
                          class="anan-popper anan-popover__popper anan-popover__popper--light with-arrow"
                          style="display: none; max-width: 320px;"
                        >
                          <div class="anan-popover__content">
                            {{ $t('key-86') }}
                          </div>
                        </div>
                      </div>

                      <p class="item-number text-overflow">
                        0
                      </p>
                      <div class="item-increase text-overflow">
                        <p class="text-overflow">
                          {{ $t('comparedToYesterday') }}
                        </p>
                        <div class="item-increase-number">
                          <p
                            class="text-overflow"
                            style="line-height: 14px;"
                          >
                            0.00%
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="item-box text-overflow">
                      <p class="item-titles text-overflow">
                        {{ $t('purchaseRate') }}
                      </p>
                      <div class="anan-popover anan-popover--light">
                        <div
                          class="anan-popper anan-popover__popper anan-popover__popper--light with-arrow"
                          style="display: none; max-width: 320px;"
                        >
                          <div class="anan-popover__content">
                            {{ $t('key-87') }}
                          </div>
                        </div>
                      </div>
                      <p class="item-number text-overflow">
                        0.00%
                      </p>
                      <div class="item-increase text-overflow">
                        <p class="text-overflow">
                          {{ $t('comparedToYesterday') }}
                        </p>
                        <div class="item-increase-number">
                          <p
                            class="text-overflow"
                            style="line-height: 14px;"
                          >
                            0.00%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
                </div>
              </b-overlay>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="card-title d-flex justify-content-between">
              <h3 class="mb-0">
                Notify
              </h3>

              <router-link :to="{ name: 'notify' }">
                <small class="mb-0">
                  {{ $t('more') }} <i class="fas fa-angle-right" />
                </small>
              </router-link>
            </div>

            <div
              v-for="i in 4"
              :key="i"
              class="d-flex mb-1"
            >
              <i
                class="fab fa-hotjar text-danger"
                style="margin-right: 10px"
              />

              <div>
                <h5 class="font-weight-bolder">
                  📢 หนังสือพิมพ์ผู้ขาย ฉบับ 29 พ.ค. 66
                </h5>
                <p class="mb-0">
                  ครบทุกเรื่องเด่นประจำสัปดาห์ ครบทุกเรื่องที่ร้านค้าไม่ควรพลาด?
                  มีอะไรบ้าง ไปอัปเดตกันเลย!?✨
                </p>

                <small class="text-muted"><small class="text-warning">ใหม่</small> · 29 พฤษภาคม
                  2023</small>
                <hr class="dropdown-divider">
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <h4 class="font-weight-bolder p-1 mb-0">
            Transection Payment
          </h4>

          <div
            v-for="item in 5"
            :key="item"
            class="paymt"
          >
            <small>00/00/00 00:00:00</small>
            <h3 class="mb-0">
              9,999.00
            </h3>

            <div class="img-bank-pay d-flex align-items-center">
              <img
                src="/bankIcon/KBANK.png"
                alt="bank-alt-1"
                height="30px"
              >
              <i
                class="fas fa-angle-double-right text-info"
                style="margin: 0 5px 0 5px;"
              />
              <img
                src="/bankIcon/SCB.png"
                alt="bank-alt-2"
                height="30px"
              >
            </div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment-timezone'
// @ts-ignore
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/th'
import Ws from '@adonisjs/websocket-client'
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    DatePicker,
    StatisticCardVertical,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BPagination,
  },
  data() {
    return {
      date: new Date(),
      itemsB: [
        { kg: 10, q: 5, type: 'สินค้าควบคุม (E)' },
        { kg: 17, q: 15, type: 'พิเศษ/ลิขสิทธิ์ (D)' },
        { kg: 33, q: 11, type: 'ธรรมดา (A)' },
        { kg: 25, q: 43, type: 'อย (C)' },
      ],
      /// //////////////////////////////////////
      data1: {
        series: [
          {
            name: 'Inflation',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
            color: ['#00e396'],
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },

          dataLabels: {
            enabled: true,
            // formatter(val) {
            //   return `${val}`
            // },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },

          xaxis: {
            categories: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            labels: {
              formatter(value) {
                return `วันที่ ${value}`
              },
            },
            position: 'top',

            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: false,
            },
          },

          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },

            labels: {
              show: false,
              formatter(val) {
                return `${val} Order`
              },
            },
          },
        },
      },
      postunmer: [],
      /// ///////////////////////////////////////////////////////

      data2: {
        series: [
          {
            name: 'Inflation',
            data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
          },
        ],
        chartOptions: {
          colors: ['#00FFB2'],
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          xaxis: {
            categories: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            position: 'top',
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter(val) {
                return `${val} สมาชิก`
              },
            },
          },
        },
      },
      /// ////////////////////////////////////////////////////
      data3: {
        series: [
          {
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
          {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            labels: {
              formatter(value) {
                return `วันที่ ${value}`
              },
            },
          },

          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter(val) {
                return `${val} Order`
              },
            },
          },
        },
      },
      ShowProduct: false,
      /// //////////////////////
      data4: {
        series: [
          {
            name: this.$t('key-313'),
            data: [2, 4, 3, 12, 1, 12, 12, 12, 200],
          },
          {
            name: this.$t('importProduct'),
            data: [1, 24, 35, 51, 49, 62, 69, 91, 148],
          },
          {
            name: this.$t('alipay-0'),
            data: [10, 41, 35, 51, 49, 100, 69, 91, 500],
          },
          {
            name: this.$t('transferToChina'),
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
          {
            name: this.$t('key-315'),
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
        ],
        chartOptions: {
          // colors: ['#8956ff'],
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
            labels: {
              formatter(value) {
                return `วันที่ ${value}`
              },
            },
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${val} บาท`
              },
            },
          },
        },
      },

      /* data5: {
        series: [{
          name: 'น้ำหนัก/กก.',
          data: [1, 1, 2, 4, 3, 12, 1, 12, 12, 12, 12, 12,]
        }, {
          name: 'คิว',
          data: [2, 2, 4, 5, 6, 22, 4, 12, 12, 12, 12, 12,]
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 430
          },
          colors: ['#83A2FF', '#FFD28F'],

          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
              colors: ['#fff']
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            shared: true,
            intersect: false
          },
          xaxis: {
            categories: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
          },
        },
      },
      */
      UserData: JSON.parse(localStorage.getItem('userData')),
      items: [
        {
          key: 'order1',
          inbox: 0,
        },
        {
          key: 'order2',
          inbox: 0,
        },
        {
          key: 'receipt',
          inbox: 0,
        },
        {
          key: 'goodsconfirm',
          inbox: 0,
        },
        {
          key: 'transfer_cn',
          inbox: 0,
        },
        {
          key: 'Alipay',
          inbox: 0,
        },
        {
          key: 'Payfor',
          inbox: 0,
        },
        {
          key: 'Payment',
          inbox: 0,
        },
      ],
      Alipays: 0,
      Payment: 0,
      Paymentin: 0,
      Transfer: 0,
      orders: 0,
      per_Alipays: null,
      per_Paymentin: null,
      per_Payments: null,
      per_Transfer: null,
      per_order: null,
      product_type: [],
      product_type1: [],
      listname: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'proKiloCar',
          label: `${this.$t('truck')}(${this.$t('key-317')})`,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'proqueueCar',
          label: `${this.$t('truck')}(${this.$t('key-18')})`,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'proKiloShip',
          label: `${this.$t('ship')}(${this.$t('key-317')})`,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'proqueueShip',
          label: `${this.$t('ship')}(${this.$t('key-18')})`,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'pro_name',
          label: this.$t('productType'),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    totalProKiloCar() {
      return this.product_type.reduce((sum, item) => sum + item.proKiloCar, 0)
    },
    totalProqueueCar() {
      return this.product_type.reduce((sum, item) => sum + item.proqueueCar, 0)
    },
    totalProKiloShip() {
      return this.product_type.reduce((sum, item) => sum + item.proKiloShip, 0)
    },
    totalProqueueShip() {
      return this.product_type.reduce(
        (sum, item) => sum + item.proqueueShip,
        0,
      )
    },
    totalProKiloCar_2() {
      return this.product_type1.reduce((sum, item) => sum + item.proKiloCar, 0)
    },
    totalProqueueCar_2() {
      return this.product_type1.reduce(
        (sum, item) => sum + item.proqueueCar,
        0,
      )
    },
    totalProKiloShip_2() {
      return this.product_type1.reduce(
        (sum, item) => sum + item.proKiloShip,
        0,
      )
    },
    totalProqueueShip_2() {
      return this.product_type1.reduce(
        (sum, item) => sum + item.proqueueShip,
        0,
      )
    },

    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    DashboardWs() {
      return this.$store.state.app.WsDashboard
    },
  },
  async mounted() {
    if (this.UserData) {
      this.CheckWsConnect()
    }
    this.getGraphData(this.date)
    // await this.Getproduct(this.date)
    this.Getproductall(this.data)
    this.Dashboard(this.data)
  },
  methods: {
    async handleChange() {
      await this.getGraphData(this.date)
      this.Getproductall(this.date)
      this.Dashboard(this.date)
    },
    time(data) {
      const formattedDate = moment(data).format('YYYY-MM-DD')
      const startOfFormattedDate = moment(formattedDate)
        .endOf('month')
        .format('YYYY-MM-DD')
      return startOfFormattedDate
    },
    // handleDateChange(date) {
    //   // รับค่าเดือน/ปี
    //   console.debug(this.time(date))
    // },
    async getGraphData(data) {
      this.ShowProduct = true
      try {
        const params = {
          data: this.time(data),
        }
        const { data: res } = await this.$http.get('/Graph/getData', {
          params,
        })
        const series = [
          {
            name: this.$t('key-313'),
            data: res.graphorder,
          },
          {
            name: this.$t('importProduct'),
            data: res.graphPaymentin,
          },
          {
            name: this.$t('alipay-0'),
            data: res.graphAlipays,
          },
          {
            name: this.$t('transferToChina'),
            data: res.graphTransfer,
          },
          {
            name: this.$t('key-315'),
            data: res.graphPayment,
          },
        ]
        // this.data1 = res.data1
        // this.data2 = res.data2
        // this.data3 = res.data3
        this.data4.series = series
        this.orders = res.orders
        this.Paymentin = res.Paymentin
        this.Alipays = res.Alipays
        this.Transfer = res.Transfer
        this.Payment = res.Payment
        this.per_order = res.per_order
        this.per_Transfer = res.per_Transfer
        this.per_Payments = res.per_Payments
        this.per_Paymentin = res.per_Paymentin
        this.per_Alipays = res.per_Alipays

        this.ShowProduct = false
      } catch (err) {
        console.log(err)
        this.ShowProduct = false
      }
    },
    Dashboard(data) {
      const params = {
        data: this.time(data),
      }
      this.$http
        .get('Graph/dashboard', { params })
        .then(response => {
          this.data1 = response.data.data1
          this.data2 = response.data.data2
          this.data3 = response.data.data3
        })
        .catch(err => {
          console.log(err)
        })
    },
    async Getproduct() {
      try {
        const { data: res } = await this.$http.get('product_type/showlistname')
        if (res.status) {
          res.data.map(ele => {
            ele.proKilo = 0
            ele.proqueue = 0
            ele.pro_initial = null
          })
          this.listname = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async Getproductall(data) {
      try {
        this.product_type = []
        this.product_type1 = []
        const params = {
          data: this.time(data),
        }
        const { data: res } = await this.$http.get('Graph/dashboard/product', {
          params,
        })
        // this.product_type = res.product
        // this.postunmer = item.data.postnumber
        // this.listname.map(ele => {
        //   ele.proKilo = 0
        //   ele.proqueue = 0
        if (res.data) {
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const item in res.data) {
            // const ele = this.listname.find(arr => arr.id == item)
            //   console.debug(ele)
            // if (ele.id === res.data[item].proId) {
            //   ele.proKilo = res.data[item].proKilo
            //   ele.proqueue = res.data[item].proqueue
            //   ele.pro_initial = res.data[item].pro_initial
            this.product_type.push(res.data[item])
            // }
          }
        }
        if (res.data1) {
          for (const item in res.data1) {
            // const ele = this.listname.find(arr => arr.id == item)
            //   console.debug(ele)
            // if (ele.id === res.data[item].proId) {
            //   ele.proKilo = res.data[item].proKilo
            //   ele.proqueue = res.data[item].proqueue
            //   ele.pro_initial = res.data[item].pro_initial
            this.product_type1.push(res.data1[item])
            // }
          }
        }
        this.product_type.sort((a, b) => {
          if (a.pro_initial < b.pro_initial) {
            return -1
          }
          if (a.pro_initial > b.pro_initial) {
            return 1
          }
          return 0
        })
        this.product_type1.sort((a, b) => {
          if (a.pro_initial < b.pro_initial) {
            return -1
          }
          if (a.pro_initial > b.pro_initial) {
            return 1
          }
          return 0
        })
        // })
      } catch (err) {
        console.log(err)
      }
    },
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.DashboardWs) {
        await this.SubWsOnDash()
        this.WsOnDash()
      } else {
        this.WsOnDash()
      }
    },
    ConnectWS() {
      const Token = localStorage.getItem('token')
      if (Token) {
        // const ws = Ws('wss://api.p2cargo.com')
        const ws = Ws(this.BaseWsUrl)
        ws.withJwtToken(Token).connect()

        ws.on('open', () => {
          console.log('Connection established')
        })
        ws.on('error', error => {
          console.log('Connection Error', error)
        })

        ws.on('close', event => {
          console.log('Connection closed', event)
        })
        this.$store.commit('app/SET_WSMAIN', ws)
      }
    },
    SubWsOnDash() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsDashboard = this.WsMain.subscribe(
        `dashboard:${UserData.agent_id}`,
      )
      this.$store.commit('app/SET_WSDASHBOARD', WsDashboard)
    },
    WsOnDash() {
      this.DashboardWs.on('open', () => {
        console.log('CartWs established')
      })
      // this.DashboardWs.emit('dashboard', '')
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.items) {
        this.DashboardWs.on(item.key, message => {
          item.inbox = message || 0
          // this.CartLength = message
        })
      }

      this.DashboardWs.on('error', error => {
        console.log('Cart Error', error)
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

@media (max-width: 767px) {
  .to-do-box-aitem {
    width: 100%;
    max-width: 500px;

    .apexcharts-xaxis-label {
      display: none;

      &:nth-child(5n) {
        display: revert;
      }
    }
  }

  .apexcharts-xaxis-texts-g {
    display: none;
  }

  //   .apexcharts-datalabels {
  //   display: none;
  // }
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  font-size: 20px !important;
}

.gap-5 {
  gap: 20px;
}

.card .card-header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom: none;
  padding: 1.5rem;
  background-color: transparent;
}

.card.card-dash {
  padding: 0px !important;
  // box-shadow: 0 1px 12px 10px rgba(132, 152, 208, 0.5) !important;
}

.card-dash .card-body {
  display: flex !important;
  align-items: center !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.card-dash .card-header {
  padding: 10px !important;
}

.card-dash .card-footer {
  font-size: 11px !important;
  padding: 5px !important;
}
</style>
